/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}

/**
 * Remove the margin in all browsers.
 */
body {
    margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */
main {
    display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
    font-size: 2em;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
    font-family: monospace, monospace;
    font-size: 1em;
}

/**
 * Remove the gray background on active links in IE 10.
 */
a {
    background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
    font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

/**
 * Add the correct font size in all browsers.
 */
small {
    font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

/**
 * Remove the border on images inside links in IE 10.
 */
img {
    border-style: none;
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
    overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
    text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
    padding: 0.35em 0.75em 0.625 emx;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    white-space: normal;
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
    vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
    overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type="checkbox"],
[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

/**
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
    display: block;
}

/**
 * Add the correct display in all browsers.
 */
summary {
    display: list-item;
}

/**
 * Add the correct display in IE 10+.
 */
template {
    display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
    display: none;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

button {
    border: none;
    background: transparent;
    color: inherit;
    font: inherit;
}

a {
    color: inherit;
}

/* Colors */
@font-face {
    font-family: 'Euclid Circular B', sans-serif;
    font-weight: normal;
    font-style: normal;
    src: local("EuclidCircularB"), url("./assets/fonts/EuclidCircularBRegular.woff") format("woff");
}

body {
    font-family: 'Euclid Circular B', sans-serif;
}

html {
    font-size: clamp(10px, calc(10px + (16 - 10) * ((100vw - 1024px) / (1920 - 1024))), 16px);
}

@font-face {
    font-family: 'Euclid Circular B', sans-serif;
    font-weight: normal;
    font-style: normal;
    src: local("EuclidCircularB"), url("./assets/fonts/EuclidCircularBRegular.woff") format("woff");
}

body {
    font-family: 'Euclid Circular B', sans-serif;
}

a {
    text-decoration: none;
}

input {
    outline: none;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

main {
    max-width: 560px;
    margin: 0 auto;
}

.section {
    width: 100vw;
    max-width: 100%;
    background-color: #FFFBF5;
    position: relative;
}

.button {
    padding: 8px 35px;
    font-size: 17px;
    line-height: 1.6;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    white-space: nowrap;
}
.button:only-child {
    margin-left: 0;
}
.button:not(:only-child) {
    max-width: 45vw;
}

.button[disabled] {
    opacity: .3;
}

.button_prev {
    color: #3C3C3C;
    background-color: #FAFAFA;
}

.button_submit, .button_next {
    color: #ffffff;
    background-color: #DEA166;
}

.button.disabled {
    opacity: 0.3;
}

.button.hidden {
    opacity: 0;
    pointer-events: none;
}

.button > * + * {
    margin-left: 8px;
}

.button_reset {
    color: #ffffff;
    background-color: transparent;
}

.button_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 24px 0;
}

.button_wrapper > * + * {
    margin-left: 24px;
}

.header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 32px 24px 24px;
}

.header__logo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 32px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
}
.header__logo img{
    max-width: 50%;
}

.header__text {
    color: #202020;
}

.header__text h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.2;
    text-align: left;
    margin-bottom: 12px;
}

.header__text p {
    font-size: 17px;
    line-height: 1.5;
}

.header__text a {
    white-space: nowrap;
}
.header__text a[href^="tel"] {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-underline-offset: 4px;
}

.survey {
    background-color: #ffffff;
    padding: 24px;
}

.survey__pagination {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.survey__pagination > * + * {
    margin-left: 30px;
}

.survey__pagination-item {
    position: relative;
    padding: 5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    color: rgba(32, 32, 32, 0.15);
    font-size: 20px;
    text-align: center;
}

.survey__pagination-item.active {
    color: #ffffff;
    background-color: #3C3C3C;
}

.survey__pagination-item.visited {
    background-color: #FAFAFA;
    color: #202020;
}

.survey__content {
    padding: 20px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border: none;
    max-width: 100vw;
}

.survey__content h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 2;
    color: #202020;
}

.survey__content > * + * {
    margin-top: 16px;
}

.survey__row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
}

.survey__row label {
    font-size: 18px;
    line-height: 1.5;
    color: #202020;
}

.survey__row > * + * {
    margin-left: 16px;
}

.survey__row input::-webkit-input-placeholder {
    opacity: 0.4;
}

.survey__row input::-moz-placeholder {
    opacity: 0.4;
}

.survey__row input:-ms-input-placeholder {
    opacity: 0.4;
}

.survey__row input::-ms-input-placeholder {
    opacity: 0.4;
}

.survey__row input::placeholder {
    opacity: 0.4;
}

.survey__input {
    padding: 8px 16px;
    background-color: #f2f2f2;
    border-radius: 4px;
    border: none;
    font-size: 18px;
    line-height: 1.5;
    color: #202020;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}

.survey__input::-webkit-input-placeholder {
    color: rgba(32, 32, 32, 0.5);
}

.survey__input::-moz-placeholder {
    color: rgba(32, 32, 32, 0.5);
}

.survey__input:-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.5);
}

.survey__input::-ms-input-placeholder {
    color: rgba(32, 32, 32, 0.5);
}

.survey__input::placeholder {
    color: rgba(32, 32, 32, 0.5);
}

.survey__input_radio {
    width: 100%;
}

.survey__input_radio > input {
    z-index: -1;
    opacity: 0;
    position: absolute;
}

.survey__input_radio > span {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.survey__input_radio > span:before {
    content: url("./assets/img/ic16-check.svg");
    display: inline-block;
    position: absolute;
    right: 16px;
    opacity: 0;
}

.survey__input_radio > input:checked + span:before {
    opacity: 1;
}

.survey__input_radio > input:checked + span {
    background-color: rgba(222, 161, 102, 0.15);
}

.room__label > input {
    z-index: -1;
    opacity: 0;
    position: absolute;
}

.room_image {
    position: relative;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
}

.room_image img {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    width: 100%;
    height: 100%;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
}

.room_image span {
    position: absolute;
    bottom: 8px;
    left: 16px;
    font-size: 18px;
    line-height: 1.5;
    color: #ffffff;
}

.room_image svg {
    position: absolute;
    right: 16px;
    display: inline-block;
    bottom: 14px;
}

.room_image svg path {
    fill: #ffffff;
}

.room__label {
    width: 50%;
}

.room__label > input:checked + .room_image {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.room__label > input:checked + .room_image img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}

.room__label > input:checked + .room_image svg path {
    fill: #27AE60;
}

.room__label > input:checked + .room_image svg {
    fill: #fff;
}

.policy {
    font-size: 12px;
    line-height: 14px;
    width: 100%;
    color: #ffffff;
    margin-bottom: 16px;
}

.policy a {
    text-decoration: underline;
}

.button_wrapper_submit {
    background-color: #3C3C3C;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 24px;
    position: fixed;
    bottom: 0;
}

.summary {
    padding: 0 24px;
}

.summary__dotts {
    flex-grow: 1;
    width: calc(100% - 10px);
    margin-left: 5px;
    margin-right: 5px;
    border-top: 1px dotted #a5a5a5;
}

.summary__row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: 6px 0;
    margin-bottom: 10px;
    z-index: 1;
}

.summary__row::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    z-index: -1;
    height: 2px;
    margin-top: -1px;
    border-top: 1px dotted #bdbdbd;;
}

.summary__row span {
    font-size: 16px;
    line-height: 1.6;
    color: #202020;
    background-color: #ffffff;
}

.summary__row span:first-child {
    font-weight: 500;
    padding-right: 12px;
}

.summary__row span:last-of-type {
    text-align: right;
    margin-left: 30px;
    padding-left: 12px;
}

.numbers {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    max-width: 64%;
}
.numbers:only-child {
    max-width: 100%;
}

.numbers input[type="number"] {
    border: none;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    width: 100%;
    padding: 0;
    margin: 0 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
    background-color: #f2f2f2;
}

.number input[type="number"]::-webkit-outer-spin-button,
.number input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

.swiper-slide {
    width: calc(100vw - 48px);
}

input[type="date"] {
    max-width: 40vw;
}

.validity {
    position: absolute;
    bottom: -16px;
    left: 0;
    font-size: 12px;
    color: #DE6666;
    height: 16px;
    opacity: 0;
    margin-left: 6px;
}

.validity_svg {
    position: absolute;
    right: 16px;
    top: 12px;
}

.validity_svg > .valid {
    display: none;
}

.validity_svg > .invalid {
    display: none;
}

input:not(:placeholder-shown).invalid ~ .validity {
    opacity: 1;
}

input:not(:placeholder-shown).invalid ~ .validity_svg > .invalid {
    display: block;
}

input:not(:placeholder-shown):not(.invalid) ~ .validity_svg > .valid {
    display: block;
}

.disabled {
    display: none;
}

.section_final-active {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    top: 0;
    z-index: 10;
    height: 100vh;
}
.survey__pagination-item:not(:last-child)::after {
    content: '>';
    position: absolute;
    right: -30px;
    width: 30px;
    color: rgba(32, 32, 32, 0.2);
    font-size: 0.7em;
}
/*=========================*/
.button_next {
    margin-left: auto;
}

.survey__input[type="date"] {
    max-width: 100%;
    width: 100%;
}

.room__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.button_prev {
    margin-right: 15px;
}

.room__label {
    width: calc(50% - 7.5px);
}

.button_wrapper_submit {
    left: 0;
    width: 100%;
    position: relative;
}

.button {
    padding: 8px 15px;
}

.survey__pagination {
    padding: 24px 24px 0;
}

.swiper-wrapper {
    padding: 0 24px;
}

.summary {
    padding: 24px;
}

.survey {
    padding: 0;
}

.button_wrapper {
    padding: 24px;
}

.quiz_popups {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 320px;
}
.quiz_popups--bg {
    background: rgba(0,0,0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}
.quiz_popups--wrapper {
    margin: 10px;
    padding: 15px 10px;
    background-color: #FFFBF5;
    min-width: 280px;
    max-height: 80%;
    border-radius: 4px;
    box-sizing: border-box;
}
.quiz_popups--close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}
.quiz_popups--close button {
    font-weight: 600;
    cursor: pointer;
    line-height: 15px;
    color: #202020;
}
.quiz_popups--close button:hover {
    opacity: 0.5;
}
.popup {
    font-size: 1.3em;

    max-height: calc(100% - 25px);
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    color: #202020;
    padding: 5px 0;

    /*-webkit-box-shadow: inset 0 8px 6px -6px black;*/
    /*-moz-box-shadow: inset 0 8px 6px -6px black;*/
    /*box-shadow: inset 0 -8px 6px -6px rgba(20,20,20, 0.2);*/
}
.popup ul {
    padding-left: 40px;
}
.popup h1,h2 {
    text-align: center;
}

.popup::-webkit-scrollbar              {
    width: 5px;
    background-color: rgba(20,20,20, 0.2);
    border-radius: 4px;
}
.popup::-webkit-scrollbar-button       { /* 2 - кнопка */display: none; }
.popup::-webkit-scrollbar-track        { /* 3 - трек */display: none; }
.popup::-webkit-scrollbar-track-piece  { /* 4 - видимая часть трека */display: none; }
.popup::-webkit-scrollbar-thumb        {
    background-color: rgba(20,20,20, 0.5);
    border-radius: 4px;
}
.popup::-webkit-scrollbar-corner       { /* 6 - уголок */display: none; }
.popup::-webkit-resizer                { /* 7 - изменение размер*/display: none;}

.popup > *:not(:last-child) {
    margin-bottom: 10px;
}

.popup ul {
    margin-bottom: 15px;
    /*list-style: ;*/
}
.vacancy {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: flex-start;
}
.vacancy--name {
    font-size: 16px;
    line-height: 1.5;
    padding-right: 12px;
}
.vacancy--more_details {
    padding: 6px 12px;
    border: 1px solid #202020;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 3px;
    font-size: 13px;
    line-height: 16px;
    margin-left: auto;
}
.vacancy--more_details svg:not(:only-child) {
    margin-left: 8px;
}
.vacancy_details--title {
    font-weight: bold;
    font-size: 20px;
    line-height: 2;
    color: #202020;
}
.vacancy_details--close {
    z-index: 1;
    right: 16px;
    top: 16px;
    position: absolute;
    cursor: pointer;
    transform: rotate(45deg);
}
.vacancy_details--close svg {
    width: 24px;
}
.vacancy_details {
    position: fixed;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    overflow-y: auto;
}
.vacancy_details--salary {
    display: flex;
    align-items: center;
}
.vacancy_details--wage + .vacancy_details--schedule {
    margin-left: 32px;
}
.vacancy_details--wage span,
.vacancy_details--schedule span {
    font-weight: 500;
}
.vacancy_details--content {
    background-color: #fff;
    padding: 32px 24px;
    position: relative;
    font-size: 17px;
    line-height: 1.5;
    max-width: 540px;
    width: 100%;
}
@media (max-width: 540px) {
    .vacancy_details--content {
        max-width: 96%;
    }
}
.vacancy_details--bg {
    background-color: rgba(0,0,0,0.3);
    position: fixed;
    width: 100%;
    height: 100%;
}
.vacancy_details--list_item {
    font-size: 14px;
    margin-left: 24px;
    display: list-item;
}
.vacancy_details--list-title span { font-weight: 600; }
.vacancy_details--list-title {
    font-weight: 500;
    margin-top: 12px;
}
.vacancy--check {
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid;
    border-radius: 2px;
    margin-right: 12px;
    position: relative;
    flex-shrink: 0;
}
.vacancy--check__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
}
.vacancy--check-checked .vacancy--check__icon{
    opacity: 1;
    visibility: visible;
}
.vacancy--check-unchecked .vacancy--check__icon {
    opacity: 0;
    visibility: hidden;
}

.countries_select {
    position: relative;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 18px;
    line-height: 1.2;
    padding: 8px 21px 8px 8px;
}
.countries_select:after {
    content: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 14 14' style='enable-background:new 0 0 14 14;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23202020;%7D%0A%3C/style%3E%3Cpath class='st0' d='M8.7,7l-5-5l0.8-0.8L10.2,7l-5.7,5.7L3.8,12L8.7,7z'/%3E%3C/svg%3E");
    position: absolute;
    top: calc(50% - 7px);
    right: 0;
    width: 14px;
    height: 14px;
    transform: rotate(90deg);
    transform-origin: center;
}
.countries_select.active:after {
    transform: rotate(270deg);
}
.countries_select--active_country {
    display: flex;
}
.countries_select--drop_down_list {
    display: none;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 100%;
    background-color: #fff;
    padding: 12px 12px 8px;
    z-index: 10;
    width: max-content;
    max-width: 80vw;
    box-shadow: 0 4px 4px rgba(0,0,0,0.2);
    border-radius: 4px;
}
.countries_select--drop_down_list div + div {
    margin-top: 8px
}
.countries_select.active .countries_select--drop_down_list{
    display: flex;
}
.countries_select--drop_down_list div:hover{
    color: #219653
}

/* N */
.is_cascade {
    display: block;
    color: #202020;
}
.is_cascade p, .is_cascade span,
.is_cascade  i{
    font-size: 17px;
    line-height: 1.5;
    margin: 0 0 16px;
    color: #202020;
}
.is_cascade li {
    margin-bottom: 2px;
    font-size: 17px;
    line-height: 1.5;
    color: #202020;
}
.is_cascade h2 {
    margin: 24px 0 16px;
    color: #202020;
    font-style: 24px;
    line-height: 1.2;
}
.is_cascade h3 {
    margin: 20px 0 16px;
    font-weight: bold;
    font-size: 20px;
    line-height: 2;
    color: #202020;

}
.is_cascade ul, .is_cascade ol{
    margin-left: 28px;
    margin-top: 16px;
}
.is_cascade i {
    font-style: italic;
}
.is_cascade b, .is_cascade strong {
    font-weight: bold;
}
.is_cascade video,
.is_cascade img,
.is_cascade iframe,
.is_cascade embed,
.is_cascade object {
    margin: 18px 0;
}
.is_cascade iframe {
    aspect-ratio: 1269 / 714;
    width: 100%;
}